@import "../node_modules/ol/ol.css";

html,
body,
#map {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: "Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif;
}

#overlay {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba($color: #ffffff, $alpha: 0.75);
  width: 25vw;
  z-index: 1;
  padding: 15px;

  h1 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;

  &:after,
  &:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
}